(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("firebase/auth"), require("firebaseui"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "firebase/auth", "firebaseui"], factory);
	else if(typeof exports === 'object')
		exports["StyledFirebaseAuth"] = factory(require("react"), require("firebase/auth"), require("firebaseui"));
	else
		root["StyledFirebaseAuth"] = factory(root["React"], root["firebase/auth"], root["firebaseui"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__639__, __WEBPACK_EXTERNAL_MODULE__531__, __WEBPACK_EXTERNAL_MODULE__507__) => {
return 