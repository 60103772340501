import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
} from 'react-router-dom';

import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth } from 'firebase/auth';

import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import './App.css';

import Home from './pages/Home';
import Landing from './pages/Landing';
import Lists from './pages/Lists';
import NameOfTheDay from './pages/NameOfTheDay';

const firebaseConfig = {
  apiKey: "AIzaSyCxbKz84E-kKeplfNeenH4R3XNH9KIRXbQ",
  authDomain: "babyganoushapp.firebaseapp.com",
  databaseURL: "https://babyganoushapp.firebaseio.com",
  projectId: "babyganoushapp",
  storageBucket: "babyganoushapp.appspot.com",
  messagingSenderId: "883861740798",
  appId: "1:883861740798:web:bc708609a7d764d43d59af",
  measurementId: "G-JF9F69YSHF"
};

const App = () => {
  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseAuth = getAuth(firebaseApp);

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    firebaseAuth.setPersistence(browserLocalPersistence);
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          setAuthenticated(!!idTokenResult.claims.admin);
        });
      } else {
        setAuthenticated(false);
      }
    });
  }, [firebaseAuth]);

  const logout = () => {
    return firebaseAuth.signOut();
  };

  return (
    <Router basename="/">
      <div className="page">
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <Typography variant="h4" color="inherit">
              <Link
                to="/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                Babyganoush
              </Link>
            </Typography>
            <Container sx={{ flexGrow: 1 }} />
            {
              authenticated && (
                <IconButton onClick={logout}>
                  <LogoutIcon style={{ color: 'white' }} />
                </IconButton>
              )
            }
          </Toolbar>
        </AppBar>
        <Container className="content" style={{ paddingTop: '6rem' }}>
          {
            authenticated ? (
              <Routes>
                <Route path="/lists/*" element={<Lists />} />
                <Route path="/notd/*" element={<NameOfTheDay />} />
                <Route path="/" element={<Home />} />
              </Routes>
            ) : (
              <Landing />
            )
          }
        </Container>
      </div>
    </Router>
  );
};

export default App;
