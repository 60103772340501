import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { each } from 'async';

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {
  ArrowBack,
} from '@mui/icons-material';
import {
  styled,
} from '@mui/material/styles';
import {
  FilteringState,
  SortingState,
  IntegratedFiltering,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  Grid as DataGrid,
  Table,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

interface IList {
  id: string;
  name: string;
  description?: string;
  list: string[];
  created: Date;
  displayHome?: boolean;
  displayHomeOrder?: number;
};

interface IDisplayOrder {
  [key: string]: number | undefined;
};

const StyledInput = styled('input')({
  display: 'none',
});

const AddOrEditList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const listData = location.state ? ((location.state as any).listData as IList) : undefined;

  const [loading, setLoading] = useState(false);
  const [listName, setListName] = useState(listData ? listData.name : '');
  const [listDescription, setListDescription] = useState(listData ? listData.description : '');
  const [listContent, setListContent] = useState<string[]>(listData ? listData.list : []);
  const [listContentAddName, setListContentAddName] = useState('');
  const [listDisplayHome, setListDisplayHome] = useState(listData ? listData.displayHome : false);
  const [listDisplayHomeOrder, setListDisplayHomeOrder] = useState(listData ? listData.displayHomeOrder : undefined);
  const [uploading, setUploading] = useState(false);

  const addNameToList = (name: string, idx?: number) => {
    const updated = listContent.slice();
    if (idx !== undefined) {
      updated[idx] = name;
    } else if (name.length > 0) {
      updated.push(name);
      setListContentAddName('');
    }

    setListContent(updated);
  };

  const submit = async () => {
    setLoading(true);
    try {
      const db = getFirestore();
      const data = {
        name: listName,
        description: listDescription,
        list: listContent,
        displayHome: listDisplayHome,
        displayHomeOrder: listDisplayHomeOrder ?? 0,
      };
      if (listData) {
        await updateDoc(doc(db, `nameLists/${listData.id}`), {
          ...data,
          updated: new Date(),
        });
      } else {
        const now = new Date();
        await addDoc(collection(db, 'nameLists'), {
          created: now,
          updated: now,
          ...data,
        });
      }
      navigate('/lists', { state: { refresh: true } });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const uploadList = (ev: React.FormEvent<HTMLInputElement>) => {
    const target = ev.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      setUploading(true);
      const file = target.files[0];
      file.text().then((contents) => {
        if (contents.includes(',')) {
          setListContent(contents.split(','));
        } else {
          setListContent(contents.split('\n'));
        }

        setUploading(false);
      });
    }
  };

  return (
    <Grid container justifyContent="left" spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h4">New List</Typography>
      </Grid>
      <Grid item xs={8}>
        <label htmlFor="upload-list-button">
          <StyledInput accept="text/*" id="upload-list-button" onChange={uploadList} type="file" />
          <Button component="span" disabled={uploading} endIcon={uploading ? <CircularProgress size="1rem" /> : undefined} variant="contained">
            Upload list
          </Button>
        </label>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="listName"
          label="List name"
          onChange={(e) => setListName(e.target.value)}
          value={listName}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="listDescription"
          label="List description"
          multiline
          onChange={(e) => setListDescription(e.target.value)}
          style={{ width: 500 }}
          value={listDescription}
        />
      </Grid>
      <Grid item xs={4}>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'left' }}>
          <Checkbox checked={listDisplayHome} onChange={() => setListDisplayHome(!listDisplayHome)} />
          Display list on Home screen
        </div>
      </Grid>
      <Grid item xs={8}>
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'left' }}>
          <TextField
            id="listDisplayHomeOrder"
            onChange={(e) => setListDisplayHomeOrder((e.target.value && e.target.value.length > 0) ? parseInt(e.target.value) : undefined)}
            style={{ marginRight: 20, width: 50 }}
            value={listDisplayHomeOrder}
          />
          Display order on Home screen
        </div>
      </Grid>
      <Grid item xs={12} flexDirection="column" style={{ marginTop: '1rem' }}>
        <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">Names</Typography>
        <Typography style={{ fontStyle: 'italic' }} variant="subtitle2">Click outside of the box to add more entries</Typography>
        <Grid container style={{ marginTop: '1rem' }}>
          {
            listContent.map((name, idx) => (
              <Grid key={`name${idx}`} item xs={12}>
                <TextField
                  id={`name${idx}`}
                  value={name}
                  onChange={(e) => addNameToList(e.target.value, idx)}
                />
              </Grid>
            ))
          }
          <Grid item xs={12}>
            <TextField
              id={`name${listContent.length}`}
              label="Add a name"
              value={listContentAddName}
              onChange={(e) => setListContentAddName(e.target.value)}
              onBlur={(e) => addNameToList(listContentAddName)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: '2rem' }} xs={12}>
        <Button
          disabled={loading}
          onClick={submit}
          variant="contained"
        >
          {listData ? 'Update' : 'Create'}
        </Button>
      </Grid>
    </Grid>
  );
};

const Lists = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState<IList[]>([]);
  const [columns] = useState([
    { name: 'name', title: 'Name' },
    { name: 'description', title: 'Description'},
    { name: 'numNames', title: 'Number of names', getCellValue: (row: IList) => (row.list.length), },
    { name: 'displayHome', title: 'Display on Home' },
    { name: 'displayHomeOrder', title: 'Display order' },
    { name: 'edit', title: 'Edit' },
    { name: 'delete', title: 'Delete' },
  ]);
  const [displayOrder, setDisplayOrder] = useState<IDisplayOrder>({});
  const [updatingDisplayOrder, setUpdatingDisplayOrder] = useState(false);

  const refreshData = async () => {
    try {
      const db = getFirestore();
      const snapshot = await getDocs(query(collection(db, 'nameLists'), orderBy('created', 'desc')));
      setLists(snapshot.docs.map((it) => ({
        id: it.id,
        name: it.data().name,
        description: it.data().description,
        list: it.data().list,
        created: it.data().created,
        displayHome: it.data().displayHome ?? false,
        displayHomeOrder: it.data().displayHomeOrder > 0 ? it.data().displayHomeOrder : undefined,
      })));
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  };

  const deleteList = async (id: string) => {
    try {
      const db = getFirestore();
      await deleteDoc(doc(db, `nameLists/${id}`));
      await refreshData();
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const updateListsDisplayOrder = async () => {
    setUpdatingDisplayOrder(true);
    try {
      const db = getFirestore();
      await each(Object.keys(displayOrder), async (id: string) => {
        return updateDoc(doc(db, `nameLists/${id}`), { displayHomeOrder: displayOrder[id] ?? 0 });
      });
      await refreshData();
      setUpdatingDisplayOrder(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setUpdatingDisplayOrder(false);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (location.state && (location.state as any).refresh === true) {
      refreshData();
    }
  }, [location]);

  const ListsDisplayCell = (props: Table.DataCellProps) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemDisplayOrder, setItemDisplayOrder] = useState<number | undefined>(props.row.displayHomeOrder);
    const [updatingDisplayHome, setUpdatingDisplayHome] = useState(false);

    const toggleDisplayHome = async () => {
      setUpdatingDisplayHome(true);
      try {
        const db = getFirestore();
        await updateDoc(doc(db, `nameLists/${props.row.id}`), { displayHome: !props.row.displayHome });
        await refreshData();
      } catch (error) {
        console.log(error);
        alert(error);
      }
      setUpdatingDisplayHome(false);
    };

    const updateItemDisplayOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
      let order: number | undefined = undefined;
      if (e.target.value && e.target.value.length > 0) {
        order = parseInt(e.target.value);
      }

      setItemDisplayOrder(order);
      const listsDisplayOrder = displayOrder;
      listsDisplayOrder[props.row.id] = order;
      setDisplayOrder(listsDisplayOrder);
    };

    switch (props.column.name) {
      case 'delete':
        return (
          <Table.Cell {...props}>
            <Button onClick={() => setDeleteDialogOpen(true)} variant="text">Delete</Button>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
              aria-labelledby={`alert-dialog-title-${props.row.id}`}
              aria-describedby={`alert-dialog-description-${props.row.id}`}
            >
              <DialogTitle id={`alert-dialog-title-${props.row.id}`}>
                Delete list?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id={`alert-dialog-description-${props.row.id}`}>
                  Are you sure you want to delete the "{props.row.name}" list?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteDialogOpen(false)}>No</Button>
                <Button onClick={() => { deleteList(props.row.id); setDeleteDialogOpen(false); }} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Table.Cell>
        );

      case 'displayHome':
        return (
          <Table.Cell {...props}>
            <Checkbox checked={props.row.displayHome === true} disabled={updatingDisplayHome} onChange={toggleDisplayHome} />
          </Table.Cell>
        );

      case 'displayHomeOrder':
        return (
          <Table.Cell {...props}>
            <TextField onChange={updateItemDisplayOrder} value={itemDisplayOrder} />
          </Table.Cell>
        );

      case 'edit':
        return (
          <Table.Cell {...props}>
            <Link state={{ listData: { ...props.row } }} to={`/lists/${props.row.id}`}>
              <Button onClick={() => setDeleteDialogOpen(true)} variant="text">Edit</Button>
            </Link>
          </Table.Cell>
        );

      default:
        return (<Table.Cell {...props} />);
    }
  };

  const ListsDisplay = () => (
    <DataGrid
      rows={lists}
      columns={columns}
    >
      <FilteringState defaultFilters={[]} />
      <IntegratedFiltering />
      <SortingState
        defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
      />
      <IntegratedSorting />
      <Table cellComponent={ListsDisplayCell} />
      <TableHeaderRow showSortingControls />
      <TableFilterRow />
    </DataGrid>
  );

  if (loading) {
    return (
      <Paper>
        <CircularProgress size="3rem" />
      </Paper>
    );
  }

  return (
    <>
      <Link to={(location.pathname === '/lists') ? '/' : '/lists'}>
        <Button startIcon={<ArrowBack />} style={{ margin: '1rem' }} variant="outlined">Back</Button>
      </Link>
      <Paper style={{ marginBottom: '2rem', padding: '1rem' }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item sm={4}>
            <Typography variant="h3">Name lists</Typography>
          </Grid>
          <Grid item sm={8}>
            {
              (location.pathname === '/lists') && (
                <>
                  <Button
                    disabled={updatingDisplayOrder}
                    endIcon={updatingDisplayOrder ? <CircularProgress size="1rem" /> : undefined}
                    onClick={updateListsDisplayOrder}
                    style={{ marginRight: 20 }}
                    variant="contained"
                  >
                    Update display order
                  </Button>
                  <Link state={{}} to="/lists/new">
                    <Button variant="contained">
                      New list
                    </Button>
                  </Link>
                </>
              )
            }
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ marginBottom: '2rem', padding: '1rem' }}>
        <Routes>
          <Route path="/:id" element={<AddOrEditList />} />
          <Route path="/new" element={<AddOrEditList />} />
          <Route path="/" element={<ListsDisplay />} />
        </Routes>
      </Paper>
    </>
  );
};

export default Lists;
