import Login from '../components/Login';

interface IProps {
  authenticated?: boolean;
}

const Landing = (props: IProps) => {
  return (
    <header className="App-header">
      <img src="/logo.svg" className="App-logo" alt="logo" />
      <h1>Admin</h1>
      {
        (props.authenticated === true) ? (
          <p>Welcome</p>
        ) : (
          <Login />
        )
      }
    </header>
  );
};

export default Landing;
