import { Link } from 'react-router-dom';

import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

interface IProps {
  title?: string;
  icon?: React.ReactElement;
  subtitle?: string;
  loading?: boolean;
  link?: string;
}

const Tile = (props: IProps) => {
  const inner = (
    <Paper className="tile">
      {
        (props.loading === true) ? (
          <CircularProgress className="tile-loading" color="inherit" size="large" />
        ) : (
          <>
            {
              props.icon ? (props.icon) : (<>{props.title && (<Typography variant="h3" color="white">{props.title}</Typography>)}</>)
            }
            {props.subtitle && (<Typography variant="h4" color="white">{props.subtitle}</Typography>)}
          </>
        )
      }
    </Paper>
  );

  return (
    <Grid item xs={6} sm={4}>
      {
        props.link ? (
          (props.link.startsWith('/')) ? (
            <Link to={props.link}>{inner}</Link>
          ) : (
            <a href={props.link}>{inner}</a>
          )
        ) : (
          <>{inner}</>
        )
      }
    </Grid>
  );
};

export default Tile;
