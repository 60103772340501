import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
} from 'firebase/auth';
import { StyledFirebaseAuth } from 'react-firebaseui/dist';

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID,
  ],
};

const Login = () => {
  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
  );
};

export default Login;
