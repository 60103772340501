import { useEffect, useState } from 'react';

import { collection, getDocs, getFirestore } from 'firebase/firestore';

import {
  Grid,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import PlayIcon from '@mui/icons-material/PlayCircleFilledOutlined';

import Tile from '../components/Tile';

const Home = () => {
  const [numLists, setNumLists] = useState<null | number>(null);
  const [numNotd, setNumNotd] = useState<null | number>(null);
  const [numUsers, setNumUsers] = useState<null | number>(null);

  useEffect(() => {
      const db = getFirestore();
      getDocs(collection(db, 'nameLists')).then((query) => {
        setNumLists(query.size);
      });
      getDocs(collection(db, 'nameOfTheDay')).then((query) => {
        setNumNotd(query.size);
      });
      getDocs(collection(db, 'users')).then((query) => {
        setNumUsers(query.size);
      });
  }, []);

  return (
    <div>
      <Grid container justifyContent="space-between"spacing={24} style={{ marginBottom: '2rem' }}>
        <Tile loading={numLists === null} title={numLists ? numLists.toString() : '0'} subtitle="Name lists" link="/lists" />
        {/* <Tile subtitle="Create a new name list" link="/lists/new" /> */}
        <Tile loading={numNotd === null} title={numNotd ? numNotd.toString() : '0'} subtitle="Name of the day entries" link="/notd" />
        <Tile loading={numUsers === null} title={numUsers ? numUsers.toString() : '0'} subtitle="Users" link="/users" />
        <Tile
          icon={<GoogleIcon style={{ color: 'white', height: '3rem', width: '3rem' }} />}
          subtitle="Firebase console"
          link="https://console.firebase.google.com/project/babyganoushapp"
        />
        <Tile
          icon={<AppleIcon style={{ color: 'white', height: '3rem', width: '3rem' }} />}
          subtitle="App Store console"
          link="https://appstoreconnect.apple.com/apps/1602021801/appstore"
        />
        <Tile
          icon={<PlayIcon style={{ color: 'white', height: '3rem', width: '3rem' }} />}
          subtitle="Play Store console"
          link="https://play.google.com/console/u/0/developers/5212156559308773500/app/4973104745909122440/app-dashboard"
        />
      </Grid>
    </div>
  );
};

export default Home;
