import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7500ff',
    },
    secondary: {
      main: '#00c070',
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  (<>
    <CssBaseline />
    <StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StrictMode>
  </>)
);
